import React from 'react';
import {Translate} from '../../components/translations';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Button from '../../components/button';
import Tabs from '../../components/tabs';

const Roadmap = () => (
  <Layout>
    <PageHero title="Hexowatch Roadmap" />
    <Section about>
      <Tabs />
      <div className="col-md-12">
        <p>
          Our mission is to help you stay one step ahead, with an AI sidekick that can monitor any website for visual,
          content, source code, technology, availability, or price changes.
        </p>
        <p>
          Our internal roadmap is focused on continual improvement so you can be the first to know when important
          changes are detected.
        </p>
        <p>
          Follow our progress via our <a href="https://hexowatch.com/academy/hexowatch-changelog/">changelog</a>.
        </p>
      </div>
    </Section>
  </Layout>
);

export default Roadmap;
